import * as OM from '@/Model';

export class Breadcrumb {
    title: string;
    link: string;
    constructor(){
        this.title = "";
        this.link = "";
    }
}

export class GWattMinEmissionCalculation extends OM.EmissionCalculation {
    grams : OM.KeyValuePairOfStringAndDouble;
    watt : OM.KeyValuePairOfStringAndDouble;
    minutes : OM.KeyValuePairOfStringAndDouble;
    countryEmission : number;

    constructor() 
    {
    	        super();
		            this.grams = new OM.KeyValuePairOfStringAndDouble();
                this.watt = new OM.KeyValuePairOfStringAndDouble();
                this.minutes = new OM.KeyValuePairOfStringAndDouble();
                this.countryEmission = null ;
        }
}

export class SingleGEmissionCalculation extends OM.EmissionCalculation {
    grams : OM.KeyValuePairOfStringAndDouble;

    constructor() 
    {
    	        super();
		            this.grams = new OM.KeyValuePairOfStringAndDouble();
        }
}

export let Roles = {
    Admin: "admin",
    Employee: "employee",
    CompanyManager: "companymanager",
    InternalUser: "internaluser",
}

export let Scopes = {
    Scope1: "Scope-01",
    Scope2: "Scope-02",
    Scope3: "Scope-03",
}
export let ScopesHexColors = {
    Scope1: "#1abc9c",
    Scope2: "#3498db",
    Scope3: "#9b59b6",
}

export let TimeFrame = {
    Year: "Year-wise",
    Month: "Month-wise",
}

export let Months = {
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    Decembre: "Decembre",
}

export let ScoringCategories = {
    CompanyManagement: "Company Management",
    Product: "Product",
    SocialResponsibility: "Social Responsibility"
}